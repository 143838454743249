import React, { useEffect, useState } from 'react';
import { Container, Box, Button, Typography, Snackbar, Alert, useTheme } from '@mui/material';
import { format, addDays, subDays } from 'date-fns';
import useMediaQuery from '@mui/material/useMediaQuery';
import NavBarFecha from '../components/Citas/NavBarFecha';
import { TablaCitas } from '../components/Citas/TablaCitas';
import { crearHorario } from '../components/Citas/Acciones/CrearHorario';
import { estaHoraOcupada } from '../components/Citas/Acciones/EstaHoraOcupada';
import { obtenerColorEstado } from '../components/Citas/Acciones/ObtenerColores';
import { DialogoAgendarCita } from '../components/Citas/Dialogos/DialogoAgendarCita';
import { DialogoEditarCita } from '../components/Citas/Dialogos/DialogoEditarCita';
import { cargarClientePorId } from '../consultas/clientes/BuscarClientePorId';
import { cargarCitas } from '../consultas/citas/CargarCitas';
import { Header } from '../components/Web/Header';
import { cargarEstilistaPorId } from '../consultas/estilistas/BuscarEstilistaPorId';
import { editarEstadoCita } from '../consultas/citas/EditarEstadoCitas';
import { DialogoGestionClientes } from '../components/Citas/Dialogos/DialogoGestionClientes';
import { DialogoGestionEstilistas } from '../components/Citas/Dialogos/DialogoGestionEstilistas';
import { eliminarCita } from '../consultas/citas/EliminarCita';
import { useParams } from 'react-router-dom';
import { parseISO } from 'date-fns';


export const PortalEmpleado = () => {
    const { fecha } = useParams();
    const fechaInicial = fecha ? parseISO(fecha) : new Date();
    const [fechaSeleccionada, setFechaSeleccionada] = useState(fechaInicial);
    // const [fechaSeleccionada, setFechaSeleccionada] = useState(fechaInicial || new Date());
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [recargarCitas, setRecargarCitas] = useState(0);
    const [dialogoClientesAbierto, setDialogoClientesAbierto] = useState(false);
    const [dialogoEstilistasAbierto, setDialogoEstilistasAbierto] = useState(false);


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date());

    const [citas, setCitas] = useState({});

    useEffect(() => {
        const cargarDatosCitas = async () => {
            try {
                const datosCitas = await cargarCitas(); 
                const citasConNombres = await Promise.all(datosCitas.map(async (cita) => {
                    const cliente = await cargarClientePorId(cita.id_cliente);
                    const estilista = await cargarEstilistaPorId(cita.id_estilista);
                    cita.nombre_cliente = cliente.Nombre; 
                    cita.nombre_estilista = estilista.nombre_estilista;
                    return cita; 
                }));

                const citasProcesadas = citasConNombres.reduce((acc, cita) => {
                    const fecha = cita.fecha;
                    const horaInicio = cita.hora_inc;
                    if (!acc[fecha]) {
                        acc[fecha] = {};
                    }
                    acc[fecha][horaInicio] = {
                        fecha: cita.fecha,
                        hora: cita.hora_inc,
                        id: cita.id,
                        id_cliente: cita.id_cliente,
                        id_estilista: cita.id_estilista,
                        cliente: cita.nombre_cliente,
                        estilista: cita.nombre_estilista,
                        servicio: cita.servicio,
                        extra: cita.extra,
                        estado: cita.estado,
                        fin: cita.hora_fin,
                    };
                    return acc;
                }, {});

                setCitas(citasProcesadas);
            } catch (error) {
                console.error("Error al cargar las citas:", error);
            }
        };

        cargarDatosCitas();
    }, [recargarCitas]);

    const [dialogoAbierto, setDialogoAbierto] = useState(false);
    const [dialogoEditarAbierto, setDialogoEditarAbierto] = useState(false);

    const [citaTemporal, setCitaTemporal] = useState({ hora: '', fin: '', cliente: '', estilista: '', servicio: '', extra: '' });
    const [citaEditar, setCitaEditar] = useState({});

    const horario = crearHorario(8, 20);

    const abrirDialogoAgendar = (hora) => {
        setCitaTemporal({ ...citaTemporal, hora, fin: hora });
        setDialogoAbierto(true);
    };

    const manejarAgendarCita = () => {
        if (!citaTemporal.cliente || !citaTemporal.estilista || !citaTemporal.servicio || !citaTemporal.fin) {
            alert('Por favor, complete todos los campos para agendar la cita.');
            return;
        }
        const fechaFormato = format(fechaSeleccionada, 'yyyy-MM-dd');
        const citasDelDia = citas[fechaFormato] || {};
        const citasActualizadas = {
            ...citas,
            [fechaFormato]: {
                ...citasDelDia,
                [citaTemporal.hora]: {
                    ...citaTemporal,
                    estado: 'Agendada',
                },
            },
        };
        setCitas(citasActualizadas);
        setDialogoAbierto(false);
        setCitaTemporal({ hora: '', fin: '', cliente: '', estilista: '', servicio: '', extra: '' });
        setSnackbarMessage('Cita agendada con éxito');
        setSnackbarOpen(true);
    };

    const abrirDialogoEditar = (hora) => {
        setCitaEditar({ ...citas[format(fechaSeleccionada, 'yyyy-MM-dd')][hora], hora });
        setDialogoEditarAbierto(true);
    };

    const manejarEditarCita = (citaEditada) => {
        const fechaFormato = format(fechaSeleccionada, 'yyyy-MM-dd');
        const citasDelDia = citas[fechaFormato] || {};
        setCitas({
            ...citas,
            [fechaFormato]: {
                ...citasDelDia,
                [citaEditada.hora]: citaEditada,
            },
        });
        setDialogoEditarAbierto(false);
        setSnackbarMessage('Cita editada con éxito');
        setSnackbarOpen(true);
    };

    const fechaFormato = format(fechaSeleccionada, 'yyyy-MM-dd');
    const citasDelDia = citas[fechaFormato] || {};

    const completarCita = async (fecha, horaInc) => {
        const resultado = await editarEstadoCita(fecha, horaInc, 'Completada');
        if (resultado) {
            setSnackbarMessage('Cita completada con éxito');
            setSnackbarOpen(true);
            setRecargarCitas(prev => prev + 1); // Incrementa el trigger de recarga
        } else {
            setSnackbarMessage('Error al completar la cita');
            setSnackbarOpen(true);
        }
    };

    const cancelarCita = async (fecha, horaInc) => {
        const resultado = await editarEstadoCita(fecha, horaInc , 'Cancelada');
        if (resultado) {
            setSnackbarMessage('Cita cancelada con éxito');
            setSnackbarOpen(true);
            setRecargarCitas(prev => prev + 1); // Incrementa el trigger de recarga
        } else {
            setSnackbarMessage('Error al cancelar la cita');
            setSnackbarOpen(true);
        }
    };


    const volverCita = async (fecha, horaInc) => {
        const resultado = await editarEstadoCita(fecha, horaInc, 'Agendada');
        if (resultado) {
            setSnackbarMessage('Cita agendada con éxito');
            setSnackbarOpen(true);
            setRecargarCitas(prev => prev + 1); // Incrementa el trigger de recarga
        } else {
            setSnackbarMessage('Error al agendada la cita');
            setSnackbarOpen(true);
        }
    };

    const eliminarCitaBD = async (fecha, horaInc) => {
        try {
            const resultado = await eliminarCita(fecha, horaInc);
            if (resultado && resultado.status === 'success') {
                setSnackbarMessage('Cita eliminada con éxito');
                setSnackbarOpen(true);
                setRecargarCitas(prev => prev + 1); // Incrementa el trigger de recarga
            } else {
                setSnackbarMessage('Error al eliminar la cita');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error al intentar eliminar la cita: ', error);
            setSnackbarMessage('Error al eliminar la cita');
            setSnackbarOpen(true);
        }
    };

    const cambiarDia = (cambio) => {
        const nuevaFecha = cambio === 1 ? addDays(fechaSeleccionada, 1) : subDays(fechaSeleccionada, 1);
        setFechaSeleccionada(nuevaFecha);
    };


    const abrirDialogoClientes = () => {
        setDialogoClientesAbierto(true);
    };

    // Función para cerrar el diálogo de gestión de clientes
    const cerrarDialogoClientes = () => {
        setDialogoClientesAbierto(false);
    };

    const abrirDialogoEstilistas = () => {
        setDialogoEstilistasAbierto(true);
    };

    // Función para cerrar el diálogo de gestión de clientes
    const cerrarDialogostilistas = () => {
        setDialogoEstilistasAbierto(false);
    };

    return (
        <>
            <Header />
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Container maxWidth="lg" sx={{ mt: 4 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 2
                    }}
                >
                    <Button variant="contained" color="primary" onClick={abrirDialogoClientes}>
                        Gestión de Clientes
                    </Button>
                    <Typography variant="h6">
                        Agenda Peluquería
                    </Typography>
                    <Button variant="contained" color="primary" onClick={abrirDialogoEstilistas}>
                        Gestión de Empleados
                    </Button>
                </Box>
                <NavBarFecha fechaSeleccionada={fechaSeleccionada} cambiarDia={cambiarDia} />
                <TablaCitas
                    horario={horario}
                    citasDelDia={citasDelDia}
                    fechaSeleccionada={fechaSeleccionada} 
                    abrirDialogoAgendar={abrirDialogoAgendar}
                    completarCita={completarCita}
                    abrirDialogoEditar={abrirDialogoEditar}
                    cancelarCita={cancelarCita}
                    volverCita={volverCita}
                    eliminarCita={eliminarCitaBD}
                    obtenerColorEstado={obtenerColorEstado}
                    estaHoraOcupada={estaHoraOcupada}
                />
                <DialogoAgendarCita
                    fullScreen={fullScreen}
                    abierto={dialogoAbierto}
                    onClose={() => setDialogoAbierto(false)}
                    citaTemporal={citaTemporal}
                    setCitaTemporal={setCitaTemporal}
                    manejarAgendarCita={manejarAgendarCita}
                    fechaSeleccionada={fechaSeleccionada}
                />
                <DialogoEditarCita
                    fullScreen={fullScreen}
                    abierto={dialogoEditarAbierto}
                    onClose={() => setDialogoEditarAbierto(false)}
                    citaEditar={citaEditar}
                    setCitaEditar={setCitaEditar}
                    manejarEditarCita={manejarEditarCita}
                />
                <DialogoGestionClientes
                    abierto={dialogoClientesAbierto} 
                    onClose={cerrarDialogoClientes} />
                <DialogoGestionEstilistas
                    abierto={dialogoEstilistasAbierto}
                    onClose={cerrarDialogostilistas} />
            </Container>
        </>
    );
};