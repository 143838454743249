import axios from 'axios';

const baseUrl = 'https://citanormal.vmestilistes.com/ws/editarEstadoCita.php';

export const editarEstadoCita = async (fecha, hora_inc, nuevoEstado) => {
    const cita = {
        fecha: fecha,
        hora_inc: hora_inc,
        estado: nuevoEstado,
    };

    try {
        const response = await axios.post(baseUrl, JSON.stringify(cita), {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate', // Evita la caché
                'Pragma': 'no-cache', // Para compatibilidad con HTTP/1.0
                'Expires': '0', // Expira inmediatamente
                'Content-Type': 'application/json',
                'Code': 'pass23sd2aASED6', // Asegúrate de que la clave coincida con la esperada en el servidor
            },
        });

        if (response.data.status === 'success') {
            console.log('Estado de la cita actualizado con éxito');
            return response.data;
        } else {
            console.error('Error al actualizar el estado de la cita', response.data.message);
            throw new Error(response.data.message);
        }
    } catch (error) {
        console.error('Error al actualizar el estado de la cita', error);
        throw error;
    }
};