import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { GestionClientes } from "../pages/GestionClientes";
import { GestionEstilistas } from "../pages/GestionEstilistas";
import { PortalEmpleado } from "../pages/PortalEmpleados"
import { Lista } from '../pages/Lista';


const Router = () => {
   
    return (
        <div>
            <BrowserRouter>
                <Routes>
                        <Route path="/" element={<Lista />}></Route>
                        <Route path="/lista/:fecha" element={<PortalEmpleado />} />
                        {/* Añade la ruta para GestionClientes */}
                        <Route path="/gestion-clientes" element={<GestionClientes />} />
                        {/* Añade la ruta para GestionClientes con un ID de cliente específico */}
                        <Route path="/gestion-clientes/:clienteId" element={<GestionClientes />} />
                        <Route path="/gestion-estilista" element={<GestionEstilistas />} />
                        <Route path="/gestion-estilista/:estilistaId" element={<GestionEstilistas />} />
                        {/* <Route path="/portalEmpleados" element={<PortalEmpleado />}></Route> */}

                    <Route path="*" element={<Navigate to="/" replace />} />

                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default Router