import axios from 'axios';

const baseUrl = 'https://citanormal.vmestilistes.com/ws/addCitas.php';

export const añadirCitas = async (cita) => {
    try {
        const response = await axios.post(baseUrl, cita, {
            headers: {
                'Content-Type': 'application/json',
                'code': 'pass23sd2aASED6', 
            },
        });

        if (response.data.status === 'success') {
            console.log('Cita guardada con éxito');
            return response.data; 
        } else {
            console.error('Error al guardar la cita', response.data.message);
            throw new Error(response.data.message); 
        }
    } catch (error) {
        console.error('Error al guardar la cita', error);
        throw error;
    }
};
