import axios from 'axios';

const baseUrl = 'https://citanormal.vmestilistes.com/ws/nombreEstilistaID.php';

export const cargarEstilistaPorId = async (estilistaId) => {
    try {
        const urlConId = `${baseUrl}?idEstilista=${estilistaId}`;
        const response = await axios.get(urlConId, {
            headers: {
                'code': 'pass23sd2aASED6', 
            }
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('No se pudo cargar el cliente');
        }
    } catch (error) {
        throw error;
    }
};
