import axios from 'axios';

const baseUrl = 'https://citanormal.vmestilistes.com/ws/addEstilistas.php';

export const añadirEstilista = async (nombreEstilista) => {
    try {
        const response = await axios.post(baseUrl, {
            nombre_estilista: nombreEstilista 
        }, {
            headers: {
                'Content-Type': 'application/json', // Asegúrate de enviar el tipo de contenido correcto
                'code': 'pass23sd2aASED6',
            }
        });

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('No se pudo añadir el estilista');
        }
    } catch (error) {
        throw error;
    }
};
