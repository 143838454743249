import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { cargarEstilistas } from '../../../consultas/estilistas/CargarEstilistas';
import { añadirEstilista } from '../../../consultas/estilistas/AddEstilistas';

export const SelectorEstilistas = ({ onEstilistaSeleccionado , valorActual }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [nuevoEstilista, setNuevoEstilista] = useState('');
    const [estilistas, setEstilistas] = useState([]);
    const [cargando, setCargando] = useState(false);

    useEffect(() => {
        const obtenerEstilistas = async () => {
            setCargando(true);
            try {
                const estilistasCargados = await cargarEstilistas();
                setEstilistas(estilistasCargados);
            } catch (error) {
                console.error("Error al cargar estilistas: ", error);
            } finally {
                setCargando(false);
            }
        };

        obtenerEstilistas();
    }, []);

    const handleAgregarEstilista = async () => {
        if (nuevoEstilista.trim()) {
            try {
                await añadirEstilista(nuevoEstilista);
                const estilistasActualizados = await cargarEstilistas();
                setEstilistas(estilistasActualizados);
                setNuevoEstilista('');
                setOpenDialog(false);
            } catch (error) {
                console.error("Error al añadir el nuevo estilista: ", error);
            }
        }
    };

    return (
        <>
            <Autocomplete
                value={valorActual}
                options={[...estilistas, { nombre_estilista: "+ Añadir Nuevo" }]}
                getOptionLabel={(option) => option.nombre_estilista || ''}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Estilista"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {cargando ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
                onChange={(event, nuevoValor) => {
                    if (nuevoValor && nuevoValor.nombre_estilista === "+ Añadir Nuevo") {
                        setOpenDialog(true);
                    } else if (nuevoValor) {
                        onEstilistaSeleccionado(nuevoValor);
                    }
                }}
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
            />
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Añadir un Nuevo Estilista</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nombre del Estilista"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={nuevoEstilista}
                        onChange={(e) => setNuevoEstilista(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
                    <Button onClick={handleAgregarEstilista}>Agregar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
