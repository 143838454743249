import axios from 'axios';

const baseUrl = 'https://citanormal.vmestilistes.com/ws/eliminarCita.php';

export const eliminarCita = async (fecha, hora_inc) => {
    try {
        const response = await axios({
            method: 'delete',
            url: baseUrl,
            data: {
                fecha: fecha,
                hora_inc: hora_inc,
            },
            headers: {
                'Content-Type': 'application/json',
                'Code': 'pass23sd2aASED6', // Esta es la cabecera personalizada
            },
        });

        if (response.data.status === 'success') {
            console.log('Cita eliminada con éxito');
            return response.data;
        } else {
            console.error('Error al eliminar la cita', response.data.message);
            throw new Error(response.data.message);
        }
    } catch (error) {
        console.error('Error al eliminar la cita', error);
        throw error;
    }
};
