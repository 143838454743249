import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button } from '@mui/material';
import { SelectorEstilistas } from '../Selectores/SelectorEstilistas';
import { SelectorClientes } from '../Selectores/SelectorClientes ';
import { añadirCitas } from '../../../consultas/citas/AddCitas';
import { format } from 'date-fns';


export const DialogoAgendarCita = ({ abierto, onClose, citaTemporal, setCitaTemporal, manejarAgendarCita, estilistas, setEstilistas , fechaSeleccionada }) => {
    const [duracion, setDuracion] = useState(15);

    useEffect(() => {
        if (abierto) {
            setDuracion(15);
            if (citaTemporal.hora) {
                const finFormatted = calcularHoraFin(citaTemporal.hora, 15);
                setCitaTemporal(prev => ({
                    ...prev,
                    fin: finFormatted,
                }));
            }
        }
    }, [abierto, citaTemporal.hora, setCitaTemporal]);
    

    const calcularHoraFin = (horaInicio, duracion) => {
        const inicio = new Date(`1970-01-01T${horaInicio}:00Z`);
        const fin = new Date(inicio.getTime() + duracion * 60000);
        return fin.toISOString().substr(11, 5);
    };

    const handleChange = (field, value) => {
        if (field === "hora") {
            const finFormatted = calcularHoraFin(value, duracion);
            setCitaTemporal(prev => ({
                ...prev,
                hora: value,
                fin: finFormatted,
            }));
        } else {
            setCitaTemporal(prev => ({ ...prev, [field]: value }));
        }
    };

    const actualizarDuracion = (incremento) => {
        setDuracion(prevDuracion => {
            const nuevaDuracion = Math.max(15, prevDuracion + incremento);
            const finFormatted = calcularHoraFin(citaTemporal.hora, nuevaDuracion);
            setCitaTemporal(prev => ({
                ...prev,
                fin: finFormatted,
            }));
            return nuevaDuracion;
        });
    };

    const manejarAgendarCitaConDuracion = () => {
        if (duracion !== 15) {
            const finFormatted = calcularHoraFin(citaTemporal.hora, duracion);
            setCitaTemporal(prev => ({
                ...prev,
                fin: finFormatted,
            }));
        }

        const fechaFormateada = format(fechaSeleccionada, 'yyyy-MM-dd');


        console.log(fechaFormateada)

        const citaParaGuardar = {
            fecha: fechaFormateada, 
            hora_inc: citaTemporal.hora,
            id_cliente: citaTemporal.id_cliente,
            id_estilista: citaTemporal.id_estilista,
            servicio: citaTemporal.servicio,
            extra: citaTemporal.extra,
            estado: "Agendada", 
            hora_fin: citaTemporal.fin,
        };

        añadirCitas(citaParaGuardar).then(() => {
            onClose(); 
            manejarAgendarCita(); 
        }).catch(error => {
            console.error("Error al guardar la cita:", error);
        });
    };


    
    const manejarSeleccionCliente = (cliente) => {
        handleChange('cliente', cliente ? cliente.Nombre : '');
        handleChange('id_cliente', cliente ? cliente.idCliente : ''); // Para enviar al backend
    };

    const manejarSeleccionEstilista = (estilista) => {
        handleChange('estilista', estilista ? estilista.nombre_estilista : '');
        handleChange('id_estilista', estilista ? estilista.idEstilista : ''); // Para enviar al backend
    };


    const agregarEstilista = (nombreEstilista) => {
        setEstilistas([...estilistas, nombreEstilista]);
    };
    

    return (
        <Dialog open={abierto} onClose={onClose}>
            <DialogTitle>Agendar Cita</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Para agendar una cita, por favor complete la siguiente información:
                </DialogContentText>

                <SelectorClientes
                    valorActual={citaTemporal.cliente && { Nombre: citaTemporal.cliente }}
                    onClienteSeleccionado={manejarSeleccionCliente}
                />
                <SelectorEstilistas
                    onEstilistaSeleccionado={manejarSeleccionEstilista}
                />

                <TextField
                    margin="dense"
                    id="servicio"
                    label="Servicio"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={citaTemporal.servicio}
                    onChange={(e) => handleChange('servicio', e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="extra"
                    label="Datos extras"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={citaTemporal.extra}
                    onChange={(e) => handleChange('extra', e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="horaInicio"
                    label="Hora de inicio"
                    type="time"
                    fullWidth
                    variant="outlined"
                    value={citaTemporal.hora}
                    onChange={(e) => handleChange('hora', e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', marginTop: '20px' }}>
                    <Button variant="outlined" onClick={() => actualizarDuracion(-15)}>-15 min</Button>
                    <span>{duracion} minutos</span>
                    <Button variant="outlined" onClick={() => actualizarDuracion(15)}>+15 min</Button>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={manejarAgendarCitaConDuracion}>Agendar</Button>
            </DialogActions>
        </Dialog>
    );
}