import axios from 'axios';

const baseUrl = 'https://citanormal.vmestilistes.com/ws/editarCitas.php';

export const editarCita = async (cita) => {
    console.log('Intentando editar cita con datos:', cita);

    try {
        const response = await axios.post(baseUrl, cita, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate', // Evita la caché
                'Pragma': 'no-cache', // Para compatibilidad con HTTP/1.0
                'Expires': '0', // Expira inmediatamente
                'Content-Type': 'application/json',
                'code': 'pass23sd2aASED6', 
            },
        });

        console.log('Respuesta del servidor:', response.data);


        if (response.data.status === 'success') {
            console.log('Cita actualizada con éxito');
            return true;
        } else {
            throw new Error('Error al actualizar la cita');
        }
    } catch (error) {
        console.error('Error al actualizar la cita', error);
        throw error;
    }
};
