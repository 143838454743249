import logo from './logo.svg';
import './App.css';
import Router from './routes/Router';

function App() {
	return (
		<div>
			<Router></Router>
		</div>

	);
}

export default App;
