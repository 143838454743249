import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button } from '@mui/material';
import { SelectorEstilistas } from '../Selectores/SelectorEstilistas';
import { cargarClientes } from '../../../consultas/clientes/CargarClientes';
import { SelectorClientes } from '../Selectores/SelectorClientes ';
import { editarCita } from '../../../consultas/citas/EditarCitas';
import { Snackbar, Alert } from '@mui/material';
import { cargarEstilistas } from '../../../consultas/estilistas/CargarEstilistas';


export const DialogoEditarCita = ({ abierto, onClose, citaEditar, setCitaEditar, manejarEditarCita }) => {

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    
    const handleChange = (field, value) => {
        setCitaEditar(prev => ({ ...prev, [field]: value }));
    };

    const handleEstilistaSeleccionado = (estilista) => {
        handleChange('id_estilista', estilista ? estilista.idEstilista : '');
    };

    const handleClienteSeleccionado = (cliente) => {
        handleChange('id_cliente', cliente ? cliente.idCliente : '');
    };


    const handleGuardarCambios = async () => {
        try {
            const resultado = await editarCita(citaEditar);
            if (resultado) {
                onClose();
                setSnackbarMessage('Cita actualizada con éxito');
                setSnackbarOpen(true);
                setTimeout(() => window.location.reload(), 1500); // Espera un poco antes de recargar para que el usuario vea el mensaje
                //recargarCitas(); // Función para recargar citas desde el servidor
            } else {
                alert('Error al actualizar la cita');
            }
        } catch (error) {
            console.error('Error al actualizar la cita', error);
            alert('Error al actualizar la cita');
        }
    };

    const [clientes, setClientes] = useState([]);
    const [estilistas , setEstilistas] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const clientesCargados = await cargarClientes();
                const estilistasCargados = await cargarEstilistas();
                console.log(estilistasCargados)
                setClientes(clientesCargados);
                setEstilistas(estilistasCargados)
            } catch (error) {
                console.error("Error al cargar clientes: ", error);
            }
        };

        if (abierto) fetchData();
    }, [abierto]);

    return (
        <Dialog open={abierto} onClose={onClose}>
            <DialogTitle>Editar Cita</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Para editar la cita, modifique la información deseada:
                </DialogContentText>
                <SelectorClientes
                    valorActual={clientes.find(c => c.Nombre === citaEditar.cliente)}
                    onClienteSeleccionado={handleClienteSeleccionado}
                />
                <SelectorEstilistas
                    valorActual={estilistas.find(c => c.nombre_estilista === citaEditar.estilista)}
                    onEstilistaSeleccionado={handleEstilistaSeleccionado}
                />
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                    <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <TextField
                    margin="dense"
                    id="servicio"
                    label="Servicio"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={citaEditar.servicio || ''}
                    onChange={(e) => handleChange('servicio', e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="extra"
                    label="Datos extras"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={citaEditar.extra || ''}
                    onChange={(e) => handleChange('extra', e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="horaInicio"
                    label="Hora de inicio"
                    type="time"
                    fullWidth
                    variant="outlined"
                    value={citaEditar.hora || ''}
                    onChange={(e) => handleChange('hora', e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    margin="dense"
                    id="horaFin"
                    label="Hora de fin"
                    type="time"
                    fullWidth
                    variant="outlined"
                    value={citaEditar.fin || ''}
                    onChange={(e) => handleChange('fin', e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={handleGuardarCambios}>Guardar cambios</Button>
            </DialogActions>
        </Dialog>
    );
}