import axios from 'axios';

const baseUrl = 'https://citanormal.vmestilistes.com/ws/addClientes.php';

export const añadirClientes = async (cita) => {
    try {
        const response = await axios.post(baseUrl, cita, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate', // Evita la caché
                'Pragma': 'no-cache', // Para compatibilidad con HTTP/1.0
                'Expires': '0', // Expira inmediatamente
                'Content-Type': 'application/json',
                'code': 'pass23sd2aASED6', 
            },
        });

        if (response.status === 200) {
            console.log('Cliente guardado con éxito');
            return response.data; 
        } else {
            console.error('Error al guardar el cliente', response.data.message);
            throw new Error(response.data.message); 
        }
    } catch (error) {
        console.error('Error al guardar el cliente', error);
        throw error; 
    }
};
