import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { cargarCitasPorIdCliente } from '../consultas/clientes/BuscarCitasPorId';
import { Header } from '../components/Web/Header';
import { cargarClientePorId } from '../consultas/clientes/BuscarClientePorId';

export const GestionClientes = () => {
    const { clienteId } = useParams(); 
    const [citas, setCitas] = useState(null);
    const [cargando, setCargando] = useState(false);
    const [error, setError] = useState(null);
    const [nombreCliente, setNombreCliente] = useState('');


    const navigate = useNavigate();

    const handleVolver = () => {
        navigate(-1); 
    };

    useEffect(() => {
        console.log(clienteId)
        const cargarNombreCliente = async () => {
            if (!clienteId) return;
            try {
                const datosCliente = await cargarClientePorId(clienteId);
                console.log(datosCliente)
                if (datosCliente && datosCliente.Nombre) {
                    setNombreCliente(datosCliente.Nombre);
                } else {
                    console.error("No se pudo cargar el nombre del cliente");
                }
            } catch (error) {
                console.error("Error cargando el nombre del cliente:", error);
            }
        };

        cargarNombreCliente();
    }, [clienteId]); // Asegúrate de que este efecto se ejecute cada vez que cambie clienteId


    useEffect(() => {
        console.log(clienteId)
        const cargarCitas = async () => {
            if (!clienteId) return; 
            console.log(clienteId)

            setCargando(true);
            try {
                const citasCargadas = await cargarCitasPorIdCliente(clienteId);
                console.log(citasCargadas)
                // Asegúrate de que citasCargadas es un array antes de actualizar el estado
                if (Array.isArray(citasCargadas)) {
                    setCitas(citasCargadas);
                } else {
                    console.error("Se esperaba un array, pero se recibió:", citasCargadas);
                    setCitas([]); // Asegurarse de que citas sea siempre un array
                }
                setCargando(false);
            } catch (error) {
                console.error("Error cargando las citas:", error);
                setError(error);
                setCitas([]); // Inicializar citas como un array vacío en caso de error
                setCargando(false);
            }
        };

        cargarCitas();
    }, [clienteId]);


    return (
        <div style={{ padding: '20px' }}>
            <Header />
            <Typography variant="h5" style={{ margin: '20px 0', textAlign: 'center' }}>
                Historico de citas de {nombreCliente}
            </Typography>
            <Button variant="outlined" onClick={handleVolver} style={{ marginBottom: '20px' }}>Volver</Button>
            {cargando && <CircularProgress />}
            {error && <Typography color="error" style={{ margin: '20px 0' }}>Error cargando las citas: {error.message}</Typography>}
            {citas && citas.length > 0 ? (
                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                    <Table aria-label="tabla de citas">
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Fecha</strong></TableCell>
                                <TableCell align="left"><strong>Servicio Ofrecido</strong></TableCell>
                                <TableCell align="left"><strong>Datos extras</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {citas.map((cita) => (
                                <TableRow key={cita.id} hover>
                                    <TableCell component="th" scope="row">
                                        {cita.fecha}
                                    </TableCell>
                                    <TableCell align="left">{cita.servicio}</TableCell>
                                    <TableCell align="left">{cita.extra}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography style={{ textAlign: 'center' }}>No se encontraron citas para este cliente.</Typography>
            )}
        </div>
    );
};